/* eslint-disable max-len */
import React from 'react';
import { v4 as uuid } from 'uuid';

interface CustomCSS extends React.CSSProperties {
    '--cloud-top-position'?: string;
    '--cloud-animation-delay'?: string;
    '--cloud-size'?: string;
    '--document-width'?: string;
    '--document-height'?: string;
}

const getRandomIntInclusive = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const createCloud = (): [string, number, number, number] => {
    const topBottomVersion = document.body.clientWidth <= 1920;

    let topPosition: number = 0;

    if (topBottomVersion) {
        const topChoice = getRandomIntInclusive(10, 100);
        const bottomChoice = getRandomIntInclusive(document.body.clientHeight - 10, document.body.clientHeight - 100);
        const decider = getRandomIntInclusive(0, 1);

        topPosition = decider > 0 ? topChoice : bottomChoice;
    } else {
        topPosition = getRandomIntInclusive(300, document.body.clientHeight - 300);
    }

    return [uuid(), topPosition, getRandomIntInclusive(1, 5), getRandomIntInclusive(60, 160)];
};

const translations: Record<string, Record<string, string>> = {
    fr: {
        'heading.job': 'Ingénieur logiciel',
        language: 'Langage',
        description: 'Choisissez la qualité pour vos projets',
    },
    en: {
        'heading.job': 'Software engineer',
        language: 'Language',
        description: 'Choose quality for your projects',
    },
};

const App: React.FC = () => {
    const [language, setLanguage] = React.useState('fr');
    const [clouds, setClouds] = React.useState(Array.from({ length: 5 }, () => createCloud()));

    return (
        <>
            <div className="container">
                <div className="header">
                    <div className="name">Jean Walrave</div>
                    <div className="separator" />
                    <div className="job">{translations[language]['heading.job']}</div>
                </div>
                <div className="container__separator" />
                <div className="description">{translations[language]['description']}</div>
                <div className="icon__bar">
                    <a className="white-round" href="https://github.com/Grandnainconnu" target="_blank">
                        <img className="icon__small" src="/assets/images/icons/github.png" alt="Github" />
                    </a>
                    <a href="https://www.linkedin.com/in/jean-walrave" target="_blank">
                        <img className="icon" src="/assets/images/icons/linkedin.png" alt="LinkedIn" />
                    </a>
                    <a
                        className="white-round"
                        href="https://app.comet.co/freelancer/profile/APdRknweGy?params=eyJhbm9ueW1pemUiOmZhbHNlLCJkZXNpZ25Nb2RlIjpmYWxzZSwicmVhZE9ubHkiOnRydWV9"
                        target="_blank"
                    >
                        <img className="icon__small" src="/assets/images/icons/comet.png" alt="Comet" />
                    </a>
                    <a className="white-round" href="/assets/CV.pdf" download="CV Jean Walrave" target="_blank">
                        CV
                    </a>
                    <a className="white-round" href="mailto:jean.walrave@gmail.com">
                        <img className="icon__small" src="/assets/images/icons/mail.png" alt="Me contacter" />
                    </a>
                </div>
                <div className="language__bar">
                    <p>{translations[language]['language']}</p>
                    <div className="wrapper">
                        <a onClick={() => setLanguage('fr')}>
                            <img className="icon" src="/assets/images/icons/flags/fr.png" alt="Francais" />
                        </a>
                        <a onClick={() => setLanguage('en')}>
                            <img className="icon" src="/assets/images/icons/flags/en.png" alt="English" />
                        </a>
                    </div>
                </div>
            </div>
            <div
                style={{ '--document-width': `${document.body.clientWidth}px`, '--document-height': `${document.body.clientHeight}px` } as CustomCSS}
            >
                <div className="rocket">
                    <img src="/assets/images/rocket.png" />
                </div>
                <div className="moon">
                    <img src="/assets/images/planet.png" />
                </div>
                {clouds.map(([id, position, delay, size], index) => (
                    <div
                        key={id}
                        className="cloud"
                        onAnimationEnd={() => {
                            setClouds(([...clouds]) => {
                                clouds[index] = createCloud();

                                return clouds;
                            });
                        }}
                        style={
                            {
                                '--cloud-top-position': `${position}px`,
                                '--cloud-animation-delay': `${delay}s`,
                            } as CustomCSS
                        }
                    >
                        <img
                            style={
                                {
                                    '--cloud-size': `${size}px`,
                                } as CustomCSS
                            }
                            src="/assets/images/cloud.png"
                        />
                    </div>
                ))}
            </div>
        </>
    );
};

export default App;
